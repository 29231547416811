<template>
	<div class="row">
		<div class="col-xxl-12 order-1 order-xxl-1">
		    <template>
		    	<div class="row">
		    		<div class="col-xl-4">
		    			<b-form-group label="Próbka / Próbki" label-for="clientFerm">
							<treeselect
                              key="idx"
                              :value-consists-of="'ALL'"
                              :multiple="true"
                              :clearable="true"
                              :searchable="true"
                              :disable-branch-nodes="true"
                              instanceId="idx"
                              :options="availableSamples"
                              placeholder="Wybierz próbki..."
                              @input="treeselectUpdateValue"
                              :value="treeselectGetValue('idx')"
                              :disabled="this.$route.params.chartId != null"
                            />
		    			</b-form-group>
		    		</div>
		    		<div class="col-xl-4">
		    			<b-form-group label="Kierunek badania" label-for="clientFerm">
							<v-select
							  v-model="chart.selectedResearchLine"
							  :items="availableResearchLines"
							  label="Kierunek badania"
							  chips
							  :disabled="this.$route.params.chartId != null"
							/>
		    			</b-form-group>
		    		</div>
		    		<div class="col-xl-4"></div>
		    	</div> 

	      		<div class="accordion" role="tablist" v-if="this.chart.selectedResearchLine != null">
	        		<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-button block v-b-toggle.accordion-1 variant="primary">
								Przebieg Badania
							</b-button>
						</b-card-header>
						<b-collapse
							id="accordion-1"
							visible
							accordion="my-accordion"
							role="tabpanel"
						>
							<div class="p-4">
								<template v-for="(row, idx) in this.chart.researchFlow">
									<div :key="'ac1' + idx">
										<p><b>Podłoże  PM-</b>{{ row.card }}</p>
										<p><b>Termin ważności podłoża: </b>{{ row.expiry }}</p>
										<div class="row">
											<div class="col-xl-6">
												<b-form-group label="Data rozpoczącia inkubacji">
													<b-form-datepicker
													  reset-button
													  type="date"
													  placeholder="dd.mm.rrrr"
													  size="xs"
													  :start-weekday="1"
													  :date-format-options="{
													    year: 'numeric',
													    month: 'numeric',
													    day: 'numeric',
													  }"
													  :id="'incubationStartDate' + idx"
													  v-model="row.incubationStart.date"
													>
													</b-form-datepicker>
												</b-form-group>
											</div>
											<div class="col-xl-6">
												<b-form-group label="Godzina rozpoczącia inkubacji">
													<b-form-input v-model="row.incubationStart.time"></b-form-input>
												</b-form-group>
											</div>
										</div>
										<b-form-group label="Kod cieplarki">
											<b-form-input  v-model="row.incubator.code"></b-form-input>
										</b-form-group>
										<b-form-group label="Temperatura">
											<b-form-input v-model="row.incubator.temp"></b-form-input>
										</b-form-group>
										<b-form-group label="Osoba rozpoczynająca inkubację">
											<b-form-input v-model="row.whoStarted"></b-form-input>
										</b-form-group>

										<div>
											<div 
												v-for="(incubationRow, incubationIdx) in row.incubation" 
												:key="'inc'+idx + '/' +incubationIdx"
											>
												<b-form-group label="Czas inkubacji">
													<b-form-input v-model="incubationRow.duration"></b-form-input>
												</b-form-group>

												<b-form-group label="Data zakończenia inkubacji">
													<b-form-datepicker
													  reset-button
													  type="date"
													  placeholder="dd.mm.rrrr"
													  size="xs"
													  :start-weekday="1"
													  :date-format-options="{
													    year: 'numeric',
													    month: 'numeric',
													    day: 'numeric',
													  }"
													  :id="'incubationEndDate' + idx + '/' + incubationIdx"
													  v-model="incubationRow.endDate"
													></b-form-datepicker>
												</b-form-group>

												<b-form-group label="Czas zakończenia inkubacji">
													<b-form-input v-model="incubationRow.endTime"></b-form-input>
												</b-form-group>
											</div>
										</div>

										<b-form-group label="Osoba kończąca inkubację">
											<b-form-input v-model="row.whoEnded"></b-form-input>
										</b-form-group>

										<b-form-group label="Zastosowane szczepy kontrolne">
											<b-form-textarea rows="1" v-model="row.controll"></b-form-textarea>
										</b-form-group>

										<b-form-group label="Wynik**">
											<b-form-textarea rows="1" v-model="row.results"></b-form-textarea>
										</b-form-group>
									</div>
								</template>
							</div>
		      			</b-collapse>
	      			</b-card>

	      			<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-button block v-b-toggle.accordion-2 variant="primary">
								Uzyskane wyniki
							</b-button>
						</b-card-header>
						<b-collapse
							id="accordion-2"
							visible
							accordion="my-accordion"
							role="tabpane2"
						> 
							<div class="p-4">
								<template v-for="(row, idx) in this.chart.results">
									<div :key="'ac2' + idx">
										<p>Próbka nr. <b>{{ row.sampleNumber }}</b></p>

										<b-form-group 
											v-for="param in Object.keys(row).filter( el => el != 'sampleNumber' )"
											:label="param"
											:key="'ac2' + idx + '-res' + param"
										>
											<b-form-input v-model="row[param]"/>
										</b-form-group>
									</div>
								</template>
							</div>
		      			</b-collapse>
	      			</b-card>

	      			<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-button block v-b-toggle.accordion-3 variant="primary">
								Uzyskane wyniki - potwierdzenia
							</b-button>
						</b-card-header>
						<b-collapse
							id="accordion-3"
							visible
							accordion="my-accordion"
							role="tabpane3"
						>
							<div class="p-4">
								<template v-for="(row, idx) in this.chart.resultsConfirmation">
									<div :key="'ac3' + idx">
										<p>Próbka nr. <b>{{ row.sampleNumber }}</b></p>

										<b-form-group 
											v-for="param in Object.keys(row).filter( el => el != 'sampleNumber' )"
											:label="param"
											:key="'ac3' + idx + '-res-conf' + param"
										>
											<b-form-input v-model="row[param]"/>
										</b-form-group>
									</div>
								</template>
							</div>
		      			</b-collapse>
	      			</b-card>
	  			</div>
	  		</template>

	  		<b-button @click="this.saveCard" variant="primary">Zapisz</b-button>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from "vuex";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	import {
	  getCardParamValue,
	} from "../../../../core/services/store/soil.module";

	export default {
	  name: "LabChartForm",
	  data() {
	    return {
	    	chart: {
	    		selectedSamples: {
	    			idx: []
	    		},
	    		selectedResearchLine: null,
	    		researchFlow: [],
	    		results: [],
	    		resultsConfirmation: []
	    	},
	    	stopHandler: false
	    };
	  },
	  methods: {
	  	getVal(item, param) {
	  	  if (!item) return "";

	  	  return getCardParamValue(item, param);
	  	},
	  	treeselectUpdateValue(value, researchLineIndex) {
	      let itemToChange = this.chart.selectedSamples[researchLineIndex];
	      itemToChange = value;
	      this.$set(this.chart.selectedSamples, researchLineIndex, itemToChange);
	    },
	    treeselectGetValue(researchLineIndex) {
	      let itemToGet = this.chart.selectedSamples[researchLineIndex];
	      if (typeof itemToGet === "undefined") {
	        return [];
	      }
	      return itemToGet;
	    },
	    saveCard() {
	    	this.$store.dispatch('storeLabChart', {
	    		data: {
	    			data: this.chart,
	    			orderId: this.$route.params.orderId,
	    			chartId: this.$route.params.chartId || null
	    		}
	    	});

	    	this.$router.push('/labl/labOrders');
	    }
	  },
	  watch: {
	    "chart.selectedResearchLine"() {
	    	if ( this.chart.selectedResearchLine != null && !this.$route.params.chartId  )
	    	{
	    		this.chart.researchFlow = this.chartSoilCards.map( card => {
	    			return {
	    				card: this.getVal(card, "soilMark") + " / " + this.getVal(card, "soilSerialNo"),
	    				expiry: (new Date( Date.parse(this.getVal(card, "soilDryExpiry")) )).toLocaleDateString('pl-PL'),
	    				incubationStart: {
	    					date: "",
	    					time: ""
	    				},
	    				incubator: {
	    					code: "",
	    					temp: ""
	    				},
	    				whoStarted: "",
	    				incubation: [
	    					{
	    						duration: "",
	    						endDate: "",
	    						endTime: ""
	    					}
    					],
	    				whoEnded: "",
	    				controll: "",
	    				results: ""
	    			}
	    		});

	    		let rl = this.researchLinesRaw.filter( el => el.id == this.chart.selectedResearchLine )[0] || null;
	    		
	    		if ( null == rl )
	    			return;

	    		this.chart.results = [...this.chart.selectedSamples.idx].map( (sample, i) => {
		    		let num = this.labOrder.number.split('/');
		    		num[0] += "-" + ( i + 1 );
					
		    		let ret = {
		    			sampleNumber: num.join('/'),
					}

					for ( const param of JSON.parse( rl.lab_chart_config ).resultParams )
						ret[param] = "";

		    		return ret;
		    	})

		    	this.chart.resultsConfirmation = [...this.chart.selectedSamples.idx].map( (sample, i) => {
		    		let num = this.labOrder.number.split('/');
		    		num[0] += "-" + ( i + 1 );
					
		    		let ret = {
		    			sampleNumber: num.join('/'),
					}

					for ( const param of JSON.parse( rl.lab_chart_config ).resultConfirmationParams )
						ret[param] = "";

		    		return ret;
		    	})
	    	}
	    },
	    currentChart: {
	    	handler() {
	    		if ( this.currentChart != null )
	    		{
	    			this.chart = this.currentChart.data;
	    		}
	    	},
	    	immediate: true
	    }
	  },
	  computed: {
	    ...mapGetters(["researchLinesRaw", "soilCardsAll", "labOrder", "currentChart"]),
	    chartSoilCards() {
	    	let c = this.researchLinesRaw.filter( el => el.id == this.chart.selectedResearchLine)[0];
	    	
	    	if ( c ) {
	    		return JSON.parse( c.lab_chart_config ).soilCards.trs1
	    			.map( id => this.soilCardsAll.filter( el => el.id == id )[0] || null )
	    			.filter( el => el != null )
	    	}

	    	return [];
	    },
	    researchLineDetails() {
	    	return this.researchLinesRaw.filter( el => el.id == this.chart.selectedResearchLine )[0] || null;
	    },
	    availableResearchLines() {
	    	if ( undefined == this.chart.selectedSamples.idx )
    			return [];

	    	return [ ...this.chart.selectedSamples.idx ]
	    		.map( idx => this.labOrder.samples[idx] )
	    		.map( sample => sample.research
    				.map( id => this.researchLinesRaw.filter( rl => rl.id == id )[0] || null )
    				.filter( el => el != null ) 
    				.map( rl => {
    					return {
    						text: rl.parameter + " " + rl.method,
    						value: rl.id
    					}
    				} )
				).flat()
	    },
	    availableSamples() {
	    	if ( null == this.labOrder )
	    		return [];

	    	return [{
    			id: "samples",
    			label: "Próbki",
    			children: [...this.labOrder.samples].map( (sample, i) => {
		    		let num = this.labOrder.number.split('/');
		    		num[0] += "-" + ( i + 1 );

		    		return {
		    			label: num.join('/'),
		    			id: i
		    		}
		    	})
    		}]
	    }
	  },
	  mounted() {
	    this.$store.dispatch("soilCardsList");
	    this.$store.dispatch("researchLinesList");

	    this.$store.dispatch("getLabOrder", {id: this.$route.params.orderId})

	    if ( this.$route.params.chartId ) 
	    {
	    	this.$store.dispatch("getLabChart", {id: this.$route.params.chartId});
	    }
	    // TODO: Add code to prepopulate the form on chart edit 
	  },
	};
</script>